<template>
    <main id="liste" v-if="season">
        <HeaderTab :title="season.season_label" :swiper_tabs="swiper_tabs"  :back_action="backToList"/>

        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <router-view :season="$cache.season"></router-view>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <main v-else>
        <LoadingSpinner class="col-12" />
    </main>
</template>


<script type="text/javascript">
    import Vue from 'vue'
    import { EventBus } from 'EventBus'
    import Countries from '@/assets/lang/countries/countries'
    import Navigation from "@/mixins/Navigation.js"
    import Gynecologie from "@/mixins/Gynecologie.js"

    export default {
        name: "seasonFiche",
        mixins: [Navigation, Gynecologie],
        data () {
            return {
                param_tab: '',
                actual_tab: 'seasonInformation',
                default_tab: 'seasonInformation',
                season: null,
                events_tab: {
                    "SaisonFiche::reload_season": (season_label) => {
                        this.season.season_label = season_label
                    },
                },
                tabsToReload: []
            }
        },

        mounted() {
            this.param_tab = this.$route.params.actual_tab
            this.season_id = this.$route.params.season_id
            this.init_component()
        },
        beforeDestroy() {
            this.$cache.season = null
        },
        methods: {
            async init_component() {
                this.loadSeason()
            },
            async loadSeason() {
                this.season = await this.loadSeasonById(this.season_id)
                this.$cache.season = this.season
            },

            backToList() {
                this.$router.push({name: "season"})
            }
        },
        
        computed:{
            swiper_tabs() {
                return [{
                        id: 'information',
                        label: 'gynecologie.season_information',
                        active: false,
                        href: 'seasonInformation'
                    },
                    {
                        id: 'planningMonte',
                        label: 'monte.planning_monte',
                        active: false,
                        href: 'seasonPlanningMonte',
                        hidden: !this.hasMonteAccess,
                    },
                    {
                        id: 'planningIA',
                        label: 'monte.planning_ia',
                        active: false,
                        href : 'seasonPlanningIA',
                        hidden: !this.hasMonteAccess,
                    },
                    {
                        id: 'contractConfigGlobal',
                        label: 'monte.configuration_contrats',
                        active: false,
                        href : 'seasonContractConfig',
                        hidden: !this.hasMonteAccess,
                    },
                    {
                        id: 'modeleContract',
                        label: 'monte.modele_contrat',
                        active: false,
                        href : 'seasonModeleContract',
                        hidden: !this.hasMonteAccess,
                    },
                    {
                        id: 'conditionsContract',
                        label: 'monte.conditions_contrats',
                        active: false,
                        href : 'seasonConditionsContract',
                        hidden: !this.hasMonteAccess,
                    },
                ]
            },

            hasMonteAccess() {
                return this.$store.state.userAccess.hasSaillieAccess
            },
        },
        watch:{
            '$route' (to, from) {
                const index = this.tabsToReload.indexOf(to.name)
                if(index > -1)
                {
                    this.$emit(to.name + "::reloadTabs")
                    this.tabsToReload.splice(index, 1)
                }
            }
        },
        components: {
            HeaderTab: () => import('@/components/HeaderTab'),
            LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
        }
    }
</script>
